// src/pages/ClosedPage.tsx
import React from 'react';
import './ClosedPage.css';

const ClosedPage: React.FC = () => {
  return (
    <div className="closed-page">
      <h1>Sorry, this webpage is closed for now.</h1>
      <p>Hope we will be back soon. Thank you so much for visiting! We will be up again shortly.</p>
    </div>
  );
};

export default ClosedPage;
